<!-- 销售站点主管账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column label="图名称" prop="title"></el-table-column>
            <el-table-column label="轮播图片">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="getImgUrl(scope.row.image)" :preview-src-list="getImgUrl(scope.row.image,'arr')" fit="cover"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button plain type="success" size="mini" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="mini" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
    <!-- 新建编辑 -->
    <el-dialog :title="dialogTitle" :modal-append-to-body="false" :close-on-click-modal="false" :visible="dialogIsShow" width="60%" @close="dialogClose">
        <el-form :model="formData" ref="form" label-position="top" :show-message="false" :status-icon="true" class="alertForm">
            <el-form-item label="图名称" prop="title" required style="width:100%;margin-right:0">
                <el-input v-model.trim="formData.title" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="image" required style="width:100%;margin-right:0">
                <div class="photoBox">
                    <div class="photo flex flex-wrap">
                        <div class="" v-if="formData.image">
                            <el-image :src="getImgUrl(formData.image)" fit="cover" :preview-src-list="getImgUrl(formData.image,'arr')">
                            </el-image>
                            <i class="el-icon-remove" @click="deletePhoto('image')"></i>
                        </div>
                        <label v-if="!formData.image">
                            <i class="el-icon-plus"></i>
                            <input type="file" class="uploadphoto" multiple="multiple" :value="filevalue" style="display:none" @change="upload($event,'image')" />
                        </label>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose" size="small">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" class="save" size="small">保 存</el-button>
        </div>
    </el-dialog>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            formData: {},
            dialogTitle: '',
            dialogIsShow: false,
            filevalue: '',
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function() {
            this.http.post('/admin.banner/index', {
                curr: this.curr,
                row: this.row,
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        // 图片清空
        deletePhoto(uploadelement) {
            this.formData[uploadelement] = ''
        },
        // 附件和图片上传获取接口返回的路径
        upload: function(event, uploadelement) {
            let loads2 = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'myLoading',
            });
            let files = event.target.files;
            for (var i = 0; i < files.length; i++) {
                var formData = new FormData();
                formData.append('pic', files[i]);
                this.http.post('/Upload/upFile',formData,true,true).then(re=>{
                    loads2.close();
                    if (re.code == 200) {
                        this.filevalue = "";
                        this.$set(this.formData,uploadelement,re.data.path)
                    } else {
                        this.$message.error(re.msg);
                    }
                })
            }
        },
        // 图片转换
        getImgUrl(item,type) {
            var url
            if(type=='arr'){
                url =[this.domain + item];
            }else{
                url = this.domain + item;
            }
            return url;
        },
        edit: function(row = {}) {
            if (typeof row.id == 'undefined') {
                this.dialogTitle = "新建轮播图";
            } else {
                this.dialogTitle = "编辑轮播图";
                this.formData = {};
                this.formData = JSON.parse(JSON.stringify(row));
            }
            this.dialogIsShow = true
        },
        handleDel(id) {
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.banner/delete', {
                    id: id
                }).then(() => {
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
                })
            })
        },
        //监听表单关闭事件
        dialogClose() {
            this.dialogIsShow = false;
            this.formData = {};
            this.$refs.form.resetFields();
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.http.post('/admin.banner/save', this.formData).then(() => {
                        this.dialogClose();
                        this.getList();
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                    })
                }
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
